import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

import { Metadata } from '@latitude/metadata';
import Section from '@latitude/section';
import { ALIGN, COLOR } from '@latitude/core/utils/constants';
import { ListNumbered } from '@latitude/list-numbered';
import { Heading4 } from '@latitude/heading';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { SITE_URL } from '@/utils/constants';

import BTOfferFaqSection from './_bt-offer-faq';
import BalanceTransferInfoSection from '../_balance-transfer-info';
import imgPageHero from '../../../images/credit-cards/hero-balance-transfer-offer.webp';
import imgOffer from './images/balance-transfer-promo.webp';

const HOW_TO_APPLY_STEPS = [
  'Visit Latitude Service Centre by clicking the Log in button in the top right corner or open the Latitude App (please ensure you download the latest version)',
  'Log in using your Latitude ID.',
  'Select the Balance Transfer tab and fill in the form using Promo Code: 2JHEVBHY.',
  "If you don't have a Latitude ID, simply register from the login page and follow the steps above.",
  "<div>For more information on Terms and Conditions <a href='https://assets.latitudefinancial.com/legals/terms-conditions/balance-transfer-termsconds.pdf' target='_blank'>click here</a>.</div>"
];
const IMPORTANT_INFO = [
  "<sup id='note-star'>*</sup>Balance transfer applies to eligible customer's Australian non-Latitude credit cards only. Balance transfer amounts commencing from $500 or more, up to 85% of your Latitude Low Rate Mastercard credit limit. Monthly payments required (exact amounts specified in your statement). Paying only the minimum monthly payment may not pay out the balance transfer amount before the end of the promotional period. If there is an outstanding balance after the promotional period, interest will be charged at the cash advance rate, currently 29.99% (subject to change). A once-off balance transfer fee of 3% applies to the amount being transferred. This will be charged to your Latitude Low Rate Mastercard credit card account when the balance transfer is processed.",
  'Offer expires on 31 December 2024 and is only available to existing customers who have submitted application form using promo code: 2JHEVBHY. If this promo code is not included in the application form, then the standard balance transfer rate may apply. Eligibility criteria apply. Subject to approval.',
  'Latitude Credit Card General Terms and Conditions set out the T&Cs of the Latitude Low Rate Mastercard credit card. Credit provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.'
];

function BalanceTransferOfferPage({ location }) {
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  return (
    <Layout location={location}>
      <main
        className="navigation-spacer"
        css={`
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Metadata
          title={'Balance Transfer Offer | Low Rate'}
          description={'Balance transfer 0% offer'}
          keywords="balance transfer"
          canonical={`${SITE_URL}${location.pathname}`}
          noIndex
        />

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={
              <>
                Enjoy 0% p.a. for 12 months on balance transfers.<sup>*</sup>
              </>
            }
            subTitle={
              <>
                Offer ends 31 December 2024. 3% balance transfer fee applies.
                <br />
                <small>
                  Visit the Latitude Service Centre or Latitude App to apply.
                </small>
              </>
            }
            pageImage={imgPageHero}
            pageImageAlt="0% Balance Transfer"
          />
        )}

        <Section id="how-to-apply">
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            How to apply
          </Heading4>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              margin: '10pt auto 10pt auto',
              padding: '5pt'
            }}
          >
            <div css={{ width: '350px', height: 'auto' }}>
              <img
                css={{ width: '100%' }}
                src={imgOffer}
                alt="Balance Transfer Offer"
              />
            </div>
            <div
              css={{
                maxWidth: '500px',
                padding: '0 10pt'
              }}
            >
              <ListNumbered
                data={HOW_TO_APPLY_STEPS}
                viewStyle="compact"
                counterBgColor={COLOR.BLUE_DEEP}
                counterVerticalAlign
                separator="none"
              />
            </div>
          </div>
        </Section>

        <BTOfferFaqSection anchor="faqs" />
        <BalanceTransferInfoSection
          anchor="important-Info"
          data={IMPORTANT_INFO}
        />
      </main>
    </Layout>
  );
}

export default BalanceTransferOfferPage;
