import React from 'react';

import Section from '@latitude/section';
import { ALIGN, COLOR } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import { Accordion } from '@latitude/accordion';
import { Text } from '@latitude/text';

const FREQUENTLY_ASKED_QUESTIONS = [
  {
    q: 'What fees apply to a balance transfer?',
    a: (
      <>
        You will need to pay a once-off balance transfer fee of 3% of the balance transfer amount or in accordance with
        the specific balance transfer offer when you take up a promotional offer. This fee is charged to your Latitude Low
        Rate Mastercard credit card account when your balance transfer is processed.
      </>
    )
  },
  {
    q: 'What is my balance transfer limit?',
    a: (
      <>
        The minimum amount is $500 and your total balance transfer amount cannot
        exceed 85% of your credit limit (excluding any applicable balance
        transfer fee charged).
        <br />
        <br />
        For example, if your credit limit is $10,000, the maximum balance
        transfer amount is $8,500. If you have a current balance of $3,000, this
        amount is deducted from $8,500 and as a result your maximum balance
        transfer amount is $5,500.
      </>
    )
  },
  {
    q: 'How much will I have to pay each month?',
    a: (
      <>
        Your monthly statement will specify the minimum amount you will need to pay each month. This amount also
        includes 3% of the balance transfer. However, if you only make the minimum monthly payment, you may not pay
        out the balance transfer amount before the end of the promotional period.
      </>
    )
  },
  {
    q:
      'What happens if I have outstanding balance at the end of the promotional period?',
    a: (
      <>
        If there is an outstanding balance after the promotional period,
        interest will be charged at the cash advance rate, currently
        29.99% (subject to change).
      </>
    )
  },
  {
    q:
      'Does Latitude arrange for the other credit card account(s) to be closed?',
    a: (
      <>
        No. Once Latitude has paid the balance on your other credit card
        account(s), it's your choice whether you close that card account(s).
        Remember, it's a good idea to close other credit card account(s) to
        clear or reduce your credit card debt.
      </>
    )
  },
  {
    q:
      'How long does it take for a balance transfer to be processed to my Latitude credit card account?',
    a: (
      <>
        Balance transfers can take up to 10 business days to process depending
        on the institution(s) to which the payments are being made. Please
        notify us if the balance transfer does not appear on your statement of
        nominated account.
      </>
    )
  }
];

function BTOfferFaqSection({ anchor }) {
  return (  
    <Section id={anchor}>
      <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
        Frequently Asked Questions
      </Heading4>

      <Accordion
        items={FREQUENTLY_ASKED_QUESTIONS.map((item, index, allItems) => ({
          id: 'faq-item-' + index,
          title: item.q,
          content: <Text css={{ padding: '10pt 20pt' }}>{item.a}</Text>
        }))}
      />
    </Section>
  );
}

export default BTOfferFaqSection;
